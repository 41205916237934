export const HOMEPAGE_ROUTE = '/';
export const SIGNUP_ROUTE = '/signup';
export const NOT_FOUND_ROUTE = '/404';

/**
 * Landing pages
 */
export const CONTACT_US_ROUTE = '/contact';
export const LOGO_GUIDELINES_ROUTE = '/logo-guidelines';
export const ABOUT_US_ROUTE = '/about';
export const SECURITY_ROUTE = '/security';

export const ECOMMERCE_ROUTE = '/e-commerce';
export const TECHNOLOGY_ROUTE = '/technology';

export const  CAREER_ROUTE = '/careers';
export const CAREER_DETAIL_ROUTE_BASE = '/careers/job/';
export const CAREER_DETAIL_ROUTE = CAREER_DETAIL_ROUTE_BASE + ':jobId';
export function getCareerJobLink(jobId){
	return CAREER_DETAIL_ROUTE_BASE + jobId;
}

//New Routes
export const GATEWAY_ROUTE = '/corporate/gateway';
export const POS_ROUTE = '/corporate/pos';
export const WALLET_ROUTE = '/personal/wallet';
export const PERSONAL_FAQ_ROUTE = '/personal/faq';
export const CARDS_ROUTE = '/cards';

export const CORPORATE_FAQ_ROUTE = '/corporate/faq';
export const CORPORATE_HOMEPAGE_ROUTE = '/corporate/';

export const LANDING_PAGE_ROUTES = [
	CONTACT_US_ROUTE,
	LOGO_GUIDELINES_ROUTE,
	ABOUT_US_ROUTE,
	SECURITY_ROUTE,
	ECOMMERCE_ROUTE,
  CAREER_ROUTE,
	CAREER_DETAIL_ROUTE,
	TECHNOLOGY_ROUTE,
	POS_ROUTE,
	CARDS_ROUTE,

	WALLET_ROUTE,
	GATEWAY_ROUTE,
	PERSONAL_FAQ_ROUTE,
	CORPORATE_FAQ_ROUTE,
	CORPORATE_HOMEPAGE_ROUTE,
];

/**
 * Legal Routes
 */
export const TOS_ROUTE = '/terms-and-conditions';
export const COOKIE_POLICY_ROUTE = '/cookie-policy';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const PRIVACY_POLICY_JOB_APPLICANTS_ROUTE = '/job-applicants-policy';

export const LEGAL_SCREEN_ROUTES = [
	TOS_ROUTE,
	COOKIE_POLICY_ROUTE,
	PRIVACY_POLICY_ROUTE,
	PRIVACY_POLICY_JOB_APPLICANTS_ROUTE
];
