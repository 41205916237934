import React from 'react';

import whiteFullLogo from './assets/logo-full-white.png'
import primaryFullLogo from './assets/logo-full-primary.png'

const CompanyLogo = ({variant, colorVariant, ...props}) => {
	const variants = {
		full: {
			white: whiteFullLogo,
			primary: primaryFullLogo,
		},
	};

  return (
    <img alt={''} src={variants[variant][colorVariant]} {...props} />
  );
};

CompanyLogo.defaultProps = {
	variant: 'full',
	colorVariant: 'white',
}

export default CompanyLogo;
