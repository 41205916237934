import React, {Suspense} from "react";
import { Route } from "react-router-dom";

import URLHelper from "../../Helpers/URLHelper";
import ContainerWithHeaderSpaceFix from "../../../components/Layout/ContainerWithHeaderSpaceFix";

import usePageHeader from "./usePageHeader";
import usePageFooter from "./usePageFooter";

import {ContentContainer, StaticPageLayoutWrapper} from "./styles";

const StaticPageLayout = ({component: Component, hasHeader = true, hasFooter = true, headerType,  ...rest}) => {
  const {hideHeader, hideFooter} = URLHelper.getUrlParams();
  const isHeaderDisplayed = hasHeader === true && hideHeader !== 'true';
  const isFooterDisplayed = hasFooter === true && hideFooter !== 'true';

  const PageHeaderComponent = usePageHeader();
  const PageFooterComponent = usePageFooter();

	const HeaderFixContainer = isHeaderDisplayed ? ContainerWithHeaderSpaceFix : React.Fragment;

	return (
    <Route {...rest} render={matchProps => (
      <StaticPageLayoutWrapper>
        {isHeaderDisplayed === true && <PageHeaderComponent type={headerType} />}
	      <Suspense fallback={React.Fragment}>
		      <ContentContainer>
			      <HeaderFixContainer>
				      <Component {...matchProps} />
			      </HeaderFixContainer>
	        </ContentContainer>
	        {isFooterDisplayed === true && <PageFooterComponent />}
	      </Suspense>
      </StaticPageLayoutWrapper>
    )} />
  );
};

export default StaticPageLayout;
