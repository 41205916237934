import React, {useContext} from 'react';

import {LocalisationContext} from "../../../../../../../Providers/SharedProviders/LocalisationProvider";
import DefaultButton from "../../../../../../../../components/Atomic/Buttons/DefaultButton";
import {CONTACT_US_ROUTE} from "../../../../../../../Navigation/NavigationConstants";

import MenuItem from "./components/MenuItem";
import {MenuItemsWrapper, MenuSectionContainer} from "./styles";
import Surface from "../../../../../../../../components/Atomic/Surface";

const MenuSection = ({items}) => {
	const {translations} = useContext(LocalisationContext);

  return (
    <MenuSectionContainer>
	    <MenuItemsWrapper numberOfItems={items.length}>
		    {items.map((item, index) =>
			    <MenuItem key={index} {...item} />
		    )}
	    </MenuItemsWrapper>
	    <DefaultButton surfaceComponent={Surface.Primary} to={CONTACT_US_ROUTE}>
		    {translations.sections.header.contactUsButton}
	    </DefaultButton>
    </MenuSectionContainer>
  );
};

export default MenuSection;
