import {lazy} from "react";
import Environment from "../Environment";
import {DEVELOPMENT, PRODUCTION, STAGING} from "../Environment/EnvironmentConstants";

import {
	ABOUT_US_ROUTE,
	CONTACT_US_ROUTE,
	HOMEPAGE_ROUTE,
	LOGO_GUIDELINES_ROUTE,
	NOT_FOUND_ROUTE,
	PRIVACY_POLICY_ROUTE,
	SECURITY_ROUTE,
	ECOMMERCE_ROUTE,
	TOS_ROUTE,
	COOKIE_POLICY_ROUTE,
	CAREER_ROUTE,
	CAREER_DETAIL_ROUTE,
	TECHNOLOGY_ROUTE,
	POS_ROUTE,
	WALLET_ROUTE,
	PERSONAL_FAQ_ROUTE,
	CORPORATE_FAQ_ROUTE,
	GATEWAY_ROUTE,
	CARDS_ROUTE,
	CORPORATE_HOMEPAGE_ROUTE,
	PRIVACY_POLICY_JOB_APPLICANTS_ROUTE
} from "./NavigationConstants";

import LogoGuidelinesScreen from "../../static-screens/LogoGuidelinesScreen";
import TechnologyScreen from "../../static-screens/TechnologyScreen";

const SecurityScreen = lazy(() => import ("../../static-screens/SecurityScreen"));

const NotFound = lazy(() => import("../../screens/Common/NotFoundScreen"));
const AboutUsScreen = lazy(() => import ("../../screens/Common/AboutUsScreen"));
const CardsScreen = lazy(() => import ("../../screens/Common/CardsScreen"));
const ContactScreen = lazy(() => import ("../../screens/Common/ContactScreen"));
const CareerScreen = lazy(() => import ("../../screens/Common/Career/CareerScreen"));
const CareerDetailsScreen = lazy(() => import ("../../screens/Common/Career/CareerDetailsScreen"));
const FAQScreen = lazy(() => import ("../../screens/Common/FAQScreen"));

const PersonalHomepage = lazy(() => import ("../../screens/Personal/HomepageScreen"));
const WalletScreen = lazy(() => import ("../../screens/Personal/WalletScreen"));

const GatewayScreen = lazy(() => import ("../../screens/Corporate/GatewayScreen"));
const PosScreen = lazy(() => import ("../../screens/Corporate/PosScreen"));
const ECommerceScreen = lazy(() => import ("../../screens/Corporate/E-CommerceScreen"));
const CorporateHomepageScreen = lazy(() => import ("../../screens/Corporate/HomepageScreen"));

/**
 * Legal Screens
 */
const TermsOfServiceScreen =  lazy(() => import ("../../static-screens/LegalScreens/TermsOfServiceScreen"));
const CookiePolicyScreen =  lazy(() => import ("../../static-screens/LegalScreens/CookiePolicyScreen"));
const PrivacyPolicyScreen =  lazy(() => import ("../../static-screens/LegalScreens/PrivacyPolicyScreen"));
const PrivacyPolicyJobApplicationtsScreen =  lazy(() => import ("../../static-screens/LegalScreens/PrivacyPolicyForJobApplicationts"));

const DEFAULT_MAPPING = {
	[HOMEPAGE_ROUTE]: PersonalHomepage,
	[ABOUT_US_ROUTE]: AboutUsScreen,
	[CONTACT_US_ROUTE]: ContactScreen,
	[SECURITY_ROUTE]: SecurityScreen,
	[LOGO_GUIDELINES_ROUTE]: LogoGuidelinesScreen,

	[ECOMMERCE_ROUTE]: ECommerceScreen,
	[CAREER_ROUTE]: CareerScreen,
	[CAREER_DETAIL_ROUTE]: CareerDetailsScreen,
	[TECHNOLOGY_ROUTE]: TechnologyScreen,
	[POS_ROUTE]: PosScreen,

	[TOS_ROUTE]: TermsOfServiceScreen,
	[COOKIE_POLICY_ROUTE]: CookiePolicyScreen,
	[PRIVACY_POLICY_ROUTE]: PrivacyPolicyScreen,
	[NOT_FOUND_ROUTE]: NotFound,

	[WALLET_ROUTE]: WalletScreen,
	[PERSONAL_FAQ_ROUTE]: FAQScreen,
	[CORPORATE_FAQ_ROUTE]: FAQScreen,
	[GATEWAY_ROUTE]: GatewayScreen,
	[CARDS_ROUTE]: CardsScreen,
	[CORPORATE_HOMEPAGE_ROUTE]: CorporateHomepageScreen,
	[PRIVACY_POLICY_JOB_APPLICANTS_ROUTE]: PrivacyPolicyJobApplicationtsScreen,
}

const DEVELOPMENT_OVERRIDES = {};
const STAGING_OVERRIDES = {};
const PRODUCTION_OVERRIDES = {};

const OVERRIDE_BY_ENVIRONMENT_TYPE = {
	[DEVELOPMENT]: DEVELOPMENT_OVERRIDES,
	[STAGING]: STAGING_OVERRIDES,
	[PRODUCTION]: PRODUCTION_OVERRIDES,
}

export function getRouteMappingsToScreen(){
	const environmentType = Environment.environmentType;
	const overrideObject = OVERRIDE_BY_ENVIRONMENT_TYPE[environmentType];

	let routeMappings = {...DEFAULT_MAPPING};
	Object.keys(overrideObject).forEach((routeName) => {
		if(DEFAULT_MAPPING.hasOwnProperty(routeName)){
			routeMappings[routeName] = overrideObject[routeName];
		}
	})

	return routeMappings;
}
