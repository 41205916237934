import React, {useContext} from 'react';

import CompanyLogo from "../../../../../../components/Atomic/Branding/CompanyLogo";
import ContentSizeLimiter from "../../../../../../components/UI/Containers/ContentSizeLimiter";
import Surface from "../../../../../../components/Atomic/Surface";
import Typography from "../../../../../../components/Typography";

import {
	CategoryContainer,
	CategoryItemContainer,
	CategoryItemsWrapper,
	CategoryTitle,
	ContentContainer,
	DisclaimerText,
	ItemLink,
	LinkSectionsWrapper,
	LogoAndLinksWrapper
} from "./styles";

import useLinks from "./useLinks";
import {LocalisationContext} from "../../../../../Providers/SharedProviders/LocalisationProvider";
import {APP_REGION_CY} from "../../../../../Environment/EnvironmentConstants";

const DefaultFooter = () => {
	const {translations} = useContext(LocalisationContext);
	const linkSections = useLinks();

  return (
    <Surface.DarkGradient>
	    <ContentSizeLimiter>
				<ContentContainer>
					<LogoAndLinksWrapper>
						<div><CompanyLogo /></div>
						<LinkSectionsWrapper categories={linkSections.length}>
							{linkSections.map(({category, items}, categoryIndex) => (
								<CategoryContainer key={`c-${categoryIndex}`}>
									<CategoryTitle>{category}</CategoryTitle>
									<CategoryItemsWrapper numberOfItems={items.length}>
										{items.map(({title, to, target, isNew}, index) =>
											<CategoryItemContainer key={`${categoryIndex}-l-${index}`}>
												<ItemLink to={to} target={target}>
													<Typography.ButtonText size={'sm'}>{title}</Typography.ButtonText>
												</ItemLink>
											</CategoryItemContainer>
										)}
									</CategoryItemsWrapper>
								</CategoryContainer>
							))}
						</LinkSectionsWrapper>
					</LogoAndLinksWrapper>
					<DisclaimerText
						dangerouslySetInnerHTML={{__html: window.REACT_APP_REGION === APP_REGION_CY ? translations.sections.staticPageFooter.disclaimerTextCY : translations.sections.staticPageFooter.disclaimerText}}
					/>
				</ContentContainer>
	    </ContentSizeLimiter>
    </Surface.DarkGradient>
  );
};

export default DefaultFooter;
