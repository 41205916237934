import styled from 'styled-components';
import {Link} from "react-router-dom";
import Typography from "../../../../../../../../../../components/Typography";

export const SubMenuWrapper = styled.div`
	position: absolute;
	display: none;
	
	top: 15px;
	padding-top: ${props => props.theme.sizes.md}px;
`;

export const MenuItemContainer = styled.div`
	position: relative;
	text-transform: uppercase;
  &:hover {
	  ${props => props.withSubMenu === true && `
	    ${SubMenuWrapper} {
	      display: block;
	    }
  `}
  }
`;

export const MenuItemText = styled(Typography.ButtonText)`
	display: flex;
	column-gap: ${props => props.theme.sizes.xxs}px;
	justify-content: center;
	align-items: center;
`;

export const MenuItemLink = styled(Link)`
  &, &:active, &:hover, &:visited {
	  color: inherit;
	  font-size: inherit;
	  text-decoration: none;
  }
`;

export const SubMenuContentContainer = styled.div`
	min-width: 150px;
	display: grid;
	grid-row-gap: 15px;
  border: 1px solid #e7e7e7;
	background-color: #fff;
  border-radius: 5px;
	padding: 25px;
	
	${MenuItemText} {
		justify-content: start;
	}
`;
