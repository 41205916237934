import {useContext, useMemo} from "react";

import Environment from "../../../../../Environment";
import {LocalisationContext} from "../../../../../Providers/SharedProviders/LocalisationProvider";

import {
	ABOUT_US_ROUTE,
	CARDS_ROUTE,
	CAREER_ROUTE,
	CONTACT_US_ROUTE,
	CORPORATE_FAQ_ROUTE,
	CORPORATE_HOMEPAGE_ROUTE,
	ECOMMERCE_ROUTE,
	GATEWAY_ROUTE,
	HOMEPAGE_ROUTE,
	PERSONAL_FAQ_ROUTE,
	POS_ROUTE, WALLET_ROUTE
} from "../../../../../Navigation/NavigationConstants";

export default function useMenuItems(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.sections.menu;

	return useMemo(() => [
		{
			text: translationsObject.personal.categoryText,
			hasSubMenu: true,
			items: [
				{
					text: translationsObject.common.items.overview,
					to: HOMEPAGE_ROUTE,
				},
				{
					text: translationsObject.common.items.wallet,
					to: WALLET_ROUTE,
				},
				{
					text: translationsObject.common.items.cards,
					to: CARDS_ROUTE,
				},
				{
					text: translationsObject.common.items.faq,
					to: PERSONAL_FAQ_ROUTE
				}
			]
		},
		{
			text: translationsObject.corporate.categoryText,
			hasSubMenu: true,
			items: [
				{
					text: translationsObject.common.items.overview,
					to: CORPORATE_HOMEPAGE_ROUTE,
				},
				{
					text: translationsObject.common.items.cards,
					to: CARDS_ROUTE,
				},
				{
					text: translationsObject.corporate.items.pos,
					to: POS_ROUTE
				},
				{
					text: translationsObject.products.items.gateway,
					to: GATEWAY_ROUTE,
				},
				{
					text: translationsObject.products.items.eCommerce,
					to: ECOMMERCE_ROUTE,
				},
				{
					text: translationsObject.common.items.faq,
					to: CORPORATE_FAQ_ROUTE
				}
			]
		},
		{
			text: translationsObject.company.categoryText,
			hasSubMenu: true,
			items: [
				{
					text: translationsObject.company.items.about,
					to: ABOUT_US_ROUTE,
				},
				{
					text: translationsObject.company.items.careers,
					to: CAREER_ROUTE
				},
				{
					text: translationsObject.company.items.contact,
					to: CONTACT_US_ROUTE
				},
			]
		},
		{
			text: translationsObject.blog,
			to: {pathname: Environment.blogURL},
			target: '_parent'
		},
	], [translationsObject])
}
