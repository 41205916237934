import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 48px;
	line-height: 1.2;
  font-weight: 600;

  @media all and ${({theme: {device}}) => device.sm} {
    font-size: 36px;
  }
`;

export const H3 = styled.h3`
  font-size: 28px;
	line-height: 1.2;
  font-weight: 500;

  @media all and ${({theme: {device}}) => device.sm} {
		font-size: 20px;
	  line-height: 1.3;
  }
`;

export const H4 = styled.h4`
  font-size: 18px;
	line-height: 1.5;
	font-weight: 600;
`;

function getCopyFontSize(props){
	const size = (props.size || 'MD').toUpperCase();
	switch (size){
		case 'LG':
			return 20;
		case 'MD':
			return 16;
		case 'SM':
			return 14;
		default:
			return 16;
	}
}

export const Copy = styled.div`
  font-size: ${props => getCopyFontSize(props)}px;
	line-height: 1.5;
`;

const ButtonText = styled.div`
  font-size: 12px;
	line-height: 15px;
	font-weight: 600;
	letter-spacing: 0.5px;
	text-transform: uppercase;
`;

const Typography = {
	Copy,
	H1,
	H3,
	H4,
	ButtonText
};

export default Typography;
