import React from 'react';
import styled from "styled-components";

export const StyledContainer = styled.div`
  ${props => props.fullHeight === true && `
    display: flex;
    height: 100%;
  `}
  
  margin: auto;
  max-width: ${props => props.theme.breakpoints.md};
  padding: 0 ${props => props.theme.sizes['3xl']}px;

	@media all and ${({theme: {device}}) => device.sm} {
    padding: 0 ${props => props.theme.sizes.md}px;
  }
`;

const ContentSizeLimiter = ({children, fullHeight=false, ...props}) => {
  return (
    <StyledContainer fullHeight={fullHeight} {...props}>
	    {children}
    </StyledContainer>
  );
};

export default ContentSizeLimiter;
