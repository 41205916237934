import styled from 'styled-components';

export const MenuSectionContainer = styled.div`
	display: flex;
	flex: 1;
	column-gap: ${props => props.theme.sizes.xl}px;
	align-items: center;
	justify-content: space-between;
	
	@media all and ${({theme: {device}}) => device.sm} {
		display: grid;
	}
`;

export const MenuItemsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(${props => props.numberOfItems}, max-content);
  grid-column-gap: ${props => props.theme.sizes.md}px;
`;
