const dark = require.context('./assets/dark/', true, /.png$/);
const white = require.context('./assets/white/', true, /.png$/);
const primary = require.context('./assets/primary/', true, /.png$/);

const iterator = (ctx) => ctx.keys().map(
	item => ({
		name: item.replace('./', '')
			.replace('.png', '')
			.replace('/', '-')
			.replace(' ', '')
		,
		image: ctx(item)
	}));

const colorVariationMap = {
	'dark': dark,
	'white': white,
	'primary': primary,
};

let iconMap = {};

Object.keys(colorVariationMap).map(
	colorVariation => iterator(colorVariationMap[colorVariation])
		.forEach(({name, image}) => {
			if(iconMap.hasOwnProperty(colorVariation) === false) {
				iconMap[colorVariation] = {};
			}

			iconMap[colorVariation][name] = image;
		})
);

module.exports = {
	iconMap
}
