import {useContext} from "react";

import Environment from "../../../../../Environment";
import {LocalisationContext} from "../../../../../Providers/SharedProviders/LocalisationProvider";

import {
	ABOUT_US_ROUTE,
	CARDS_ROUTE,
	CAREER_ROUTE,
	CONTACT_US_ROUTE,
	COOKIE_POLICY_ROUTE,
	ECOMMERCE_ROUTE,
	PERSONAL_FAQ_ROUTE,
	POS_ROUTE, PRIVACY_POLICY_JOB_APPLICANTS_ROUTE,
	PRIVACY_POLICY_ROUTE,
	TOS_ROUTE, WALLET_ROUTE
} from "../../../../../Navigation/NavigationConstants";

export default function useLinks() {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.sections.menu;

	return [
		{
			category: translationsObject.company.categoryText,
			items: [
				{
					title: translationsObject.company.items.about,
					to: ABOUT_US_ROUTE,
				},
				{
					title: translationsObject.company.items.careers,
					to:  CAREER_ROUTE,
				},
				{
					title: translationsObject.company.items.contact,
					to: CONTACT_US_ROUTE,
				},
				{
					title: translationsObject.common.items.faq,
					to: PERSONAL_FAQ_ROUTE,
				},
				{
					title: translationsObject.blog,
					to: {pathname: Environment.blogURL},
					target:'_parent'
				},
			]
		},
		{
			category: translationsObject.products.categoryText,
			items: [
				{
					title: translationsObject.products.items.pos,
					to: POS_ROUTE,
				},
				{
					title: translationsObject.products.items.eCommerce,
					to: ECOMMERCE_ROUTE,
				},
				{
					title: translationsObject.common.items.wallet,
					to: WALLET_ROUTE,
				},
				{
					title: translationsObject.products.items.cards,
					to: CARDS_ROUTE,
				},
			]
		},
		{
			category: translationsObject.policies.categoryText,
			items: [
				{
					title: translationsObject.policies.items.tos,
					to: TOS_ROUTE,
				},
				{
					title: translationsObject.policies.items.privacyPolicy,
					to: PRIVACY_POLICY_ROUTE,
				},
				{
					title: translationsObject.policies.items.cookiePolicy,
					to: COOKIE_POLICY_ROUTE,
				},
				{
					title: 'Privacy Policy For Job Applicants',
					to: PRIVACY_POLICY_JOB_APPLICANTS_ROUTE,
				},
			]
		}
	]

}
