import styled from 'styled-components';
import {Link} from "react-router-dom";
import Typography from "../../../../../../components/Typography";

export const ContentContainer = styled.div`
  display: grid;
	grid-row-gap: ${props => props.theme.sizes.xl}px;
	padding: ${props => props.theme.sizes.xxl}px 0;
	
	@media all and ${({theme: {device}}) => device.sm} {
    padding: ${props => props.theme.sizes.xl}px 0;
  }
`;

export const DisclaimerText = styled(Typography.Copy).attrs(() => ({size: 'sm'}))``;

export const LogoAndLinksWrapper = styled.div`
  display: flex;
	justify-content: space-between;
	column-gap: ${props => props.theme.sizes.lg}px;
	
	img {
		max-width: 139px;
	}

	@media all and ${({theme: {device}}) => device.sm} {
		flex-direction: column;
		row-gap: ${props => props.theme.sizes.lg}px;
	}
`;

export const LinkSectionsWrapper = styled.div`
  display: flex;
	column-gap: ${props => props.theme.sizes.xxl}px;
	row-gap: ${props => props.theme.sizes.lg}px;
  flex-wrap: wrap;

	@media all and ${({theme: {device}}) => device.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
	}
`;

export const CategoryContainer = styled.div`
	display: grid;
	grid-row-gap: 30px;
	grid-template-rows: max-content 1fr;
`;

export const CategoryTitle = styled(Typography.H3)``;

export const CategoryItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 15px;
	grid-template-rows: repeat(${props => props.numberOfItems}, min-content);
	align-items: start;
`;

export const CategoryItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const ItemLink = styled(Link)`
 &, &:hover, &:active, &:visited {
	 text-decoration: none;
 }
`;
