import React from 'react';
import styled from "styled-components";

import Typography from "../../../Typography";
import Icon from "../../Icon";
import Surface from "../../Surface";

import {ButtonLink, DefaultButtonSurface} from "./styles";

const ButtonContainer = styled.div`
  position: relative;
  display: inline-flex;
	
  --base-button-background-color: ${props => props.theme.colors.black.solid};
 	--base-button-text-color: ${props => props.theme.colors.white.solid};

  ${Surface.Primary} {
    background-color: transparent;
    --base-button-background-color: ${props => props.theme.colors.primary};
    --base-button-text-color: ${props => props.theme.colors.white.solid};
  }

  ${Surface.Light} {
    background-color: transparent;
    --base-button-background-color: ${props => props.theme.colors.white.solid};
    --base-button-text-color: ${props => props.theme.colors.black.solid};
	}
	
	${Surface.Dark} {
    background-color: transparent;
    --base-button-background-color: ${props => props.theme.colors.black.solid};
    --base-button-text-color: ${props => props.theme.colors.white.solid};
	}
`;

const DefaultButton = ({
	children,
	surfaceComponent: SurfaceComponent = null,
  text,
	buttonType = 'solid',
  iconName,
	iconPosition,
	iconProps = {},
  ...props
}) => {
	const ButtonSurfaceComponent = SurfaceComponent !== null ? SurfaceComponent : DefaultButtonSurface;

	return (
		<ButtonContainer>
			<ButtonSurfaceComponent>
				<ButtonLink data-type={buttonType} {...props}>
					<Typography.ButtonText>{children}{text}</Typography.ButtonText>
					{(iconName || '').length > 0 && (
						<Icon name={iconName} size={16} {...iconProps} />
					)}
				</ButtonLink>
			</ButtonSurfaceComponent>
		</ButtonContainer>
	);
};

export default DefaultButton;
