import styled from "styled-components";
import Typography from "../../Typography";
import {DefaultButtonSurface} from "../Buttons/DefaultButton/styles";

const BasePrimarySurface = styled.div``;
const BaseLightSurface = styled.div``;
const BaseDarkSurface = styled.div``;

const CommonPrimarySurface = styled(BasePrimarySurface)`
	&, ${BaseLightSurface} &, ${BaseDarkSurface} & {
		${Typography.H1}, 
		${Typography.H3}, 
		${Typography.H4} {
			color: ${props => props.theme.colors.white.solid}; 
		}
		
	  ${Typography.Copy} {
	    color: ${props => props.theme.colors.white.transparent};
	  }
	
	  ${Typography.ButtonText} {
	    color: ${props => props.theme.colors.white.transparent};
	  }
	}
`;

const PrimarySurface = styled(CommonPrimarySurface)`
  background-color: ${props => props.theme.colors.primary};
`;

const CommonLightSurface = styled(BaseLightSurface)`
  &, ${BaseDarkSurface} &, ${BasePrimarySurface} & {
    ${Typography.H1},
    ${Typography.H3},
    ${Typography.H4} {
      color: ${props => props.theme.colors.black.solid};
    }

    ${Typography.Copy} {
      color: ${props => props.theme.colors.gray.dark};
    }

    ${Typography.ButtonText} {
      color: ${props => props.theme.colors.gray.solid};
    }

    ${DefaultButtonSurface} {
      --base-button-background-color: ${props => props.theme.colors.black.solid};
      --base-button-text-color: ${props => props.theme.colors.white.solid};
    }
  }
`;

const LightSurface = styled(CommonLightSurface)`
  background: ${props => props.theme.colors.white.solid};
`;

const LightGradientSurface = styled(CommonLightSurface)`
  background: ${props => props.theme.colors.gradient.light};
`;

const LightGraySurface = styled(CommonLightSurface)`
  background:  ${props => props.theme.colors.gray.light};
`;

const CommonDarkSurface = styled(BaseDarkSurface)`
  ${Typography.H1},
  ${Typography.H3},
  ${Typography.H4} {
    color: ${props => props.theme.colors.white.solid};
  }

  ${Typography.Copy} {
    color: ${props => props.theme.colors.white.transparent};
  }

  ${Typography.ButtonText} {
    color: ${props => props.theme.colors.white.transparent};
  }

  ${DefaultButtonSurface} {
    --base-button-background-color: ${props => props.theme.colors.white.solid};
    --base-button-text-color: ${props => props.theme.colors.black.solid};
  }
`;

const DarkSurface = styled(CommonDarkSurface)`
  background-color: ${props => props.theme.colors.black.solid};
`;

const DarkGradientSurface = styled(CommonDarkSurface)`
  background:  ${props => props.theme.colors.gradient.dark};
`;

const Surface = {
	Primary: PrimarySurface,
	Light: LightSurface,
	LightGray: LightGraySurface,
	LightGradient: LightGradientSurface,
	Dark: DarkSurface,
	DarkGradient: DarkGradientSurface
};

export default Surface;
